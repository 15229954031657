.App {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(#64D9D0, #4E8CE3);
}

.my-info {
  font-size: 2.5rem;
  color: white;
  position: fixed;
  bottom: 4px;
  right: 10px;
}

.my-info,
button:hover {
  opacity: 0.8;
  cursor: pointer;
}