/* Pc */
@media (min-width: 1024px){
    
}

/* Tablet */
@media (min-width: 740px) and (max-width: 1023px) {
    
    
}

/* Mobile */
@media (max-width: 739px){
    .App {
        .todo-app {
            width: 80%;
            height: 70%;

            .todo-list {
                height: 66%;
                overflow: auto;
            }
        }
    }
}
